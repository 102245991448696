import "./App.css";
import Form from "./Pages/Form";

function App() {
  return (
    <>
      <Form />
    </>
  );
}

export default App;
